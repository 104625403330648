import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"
import arrowSvg from "../images/arrow-circle-right-solid.svg"
import liveTrackingImg from "../images/live-tracking-sq-small.jpg"

class HGS12ServicePage extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="HGS12"
          description="View route information and buy your ticket for the HGS12 service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">HGS12 service</h1>
              <p>All bus stops between Savins Mill way - Heckmondwike Grammar School, Wakefield</p>
              <p>Heckmondwike Grammar School, Wakefield - Savins Mill way</p>
              <p style={{ margin: 0 }}>
                <a className='btn btn-grow btn-quote btn-ticket' href="https://passenger.shuttleid.uk/client/3108be57-5c93-4d16-84ff-0990d87eba95/8b04d199-c022-4b71-87e5-45c1534ec596">
                  <span className='btn-quote__text'>Buy ticket</span>
                  <img className='btn-quote__icon' src={arrowSvg} alt="" />
                </a>
                <a
                  style={{ display: "inline-block", marginLeft: 15 }}
                  href="https://passenger.shuttleid.uk"
                >
                  View My Tickets
                </a>
              </p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <p style={{ margin: 0 }}>
                  <img src={liveTrackingImg} alt="" />
                </p>
              </div>
              <div className="page-content__text">
                <h2 style={{ marginBottom: 15 }}>NEW: Live vehicle tracking</h2>
                <p>Users of the HGS12 service will be able to access live vehicle tracking directly from their ticket, starting January 2022.</p>
                <p>Our new "track bus" feature will allow students and parents to stay informed in real-time, and will complement our existing SMS service alerts, to offer the safest travel experience possible for your child.</p>
              </div>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <h2>Route</h2>
                <h3 style={{ margin: '10px 0' }}>AM route</h3>
                <iframe
                  title="HGS12 AM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1nP3JYbmkGpJwQwBzXm8sNfcR7FtSNPM&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>Towards</strong> Heckmondwike grammar school, Wakefield
                </p>
                <p>
                  Savins Mill way (Bus stop by Kirkstall morrisons), (R) Bridge
                  Road, (L) Abbey Road A65, New Road Side, (R) Ring Road A6120, (L) King
                  Lane, U Turn at Alwoodley park and Ride, Ring Road, (R) Harrogate Road,
                  Lidgett Lane, Gledhow Lane, Roundhay Road, A58, A643, Elland Road, (L) Ring
                  Road, (R) Elland Road (Churwell Hill), Victoria Road, Bruntcliffe Lane, Howden
                  Clough Road, Leeds Road None stop to Heckmondwike Grammar School
                </p>

                <h3 style={{ margin: '10px 0' }}>PM route</h3>
                <iframe
                  title="HGS12 PM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1oWQxXW_AJ88fPN0sSviwn4eAEuU_mU0&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>From</strong> Heckmondwike grammar school, Wakefield
                </p>
                <p>
                  From Heckmondwike Grammar School None Stop to, Leeds
                  Road, Huddersfield Road, Leeds Road, Howden Clough Road, Bruntcliffe
                  Lane, Victoria Road, Elland Road (Churwell Hill) , Ring Road, Beeston
                  Interchange, M621, A643, A58, Easterly Road, Oakwood Lane, Roundhay
                  Road, Gledhow Lane, Lidgett Lane, Harrogate Road, Street Lane, King Lane,
                  King Lane Park and Ride (U Turn at roundabout), King Lane, Ring Road, A65
                  New Side Road, Abbey Road, Kirkstall Lights.
                </p>
              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>
                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Morning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Kirkstall Morrison's</td>
                      <td>DEP</td>
                      <td>07:00</td>
                    </tr>
                    <tr>
                      <td>Ring Road at Fink Hill</td>
                      <td>DEP</td>
                      <td>07:08</td>
                    </tr>
                    <tr>
                      <td>King Lane Park and Ride</td>
                      <td>DEP</td>
                      <td>07:23</td>
                    </tr>
                    <tr>
                      <td>Oakwood Clock</td>
                      <td>DEP</td>
                      <td>07:35</td>
                    </tr>
                    <tr>
                      <td>Churwell Hill</td>
                      <td>DEP</td>
                      <td>07:55</td>
                    </tr>
                    <tr>
                      <td>Heckmondwike Grammar</td>
                      <td>ARR</td>
                      <td>08:30</td>
                    </tr>
                  </tbody>
                </table>

                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Afternoon</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Heckmondwike Grammar</td>
                      <td>DEP</td>
                      <td>15:30</td>
                    </tr>
                    <tr>
                      <td>Churwell Hill</td>
                      <td>DEP</td>
                      <td>15:40</td>
                    </tr>
                    <tr>
                      <td>Oakwood Clock</td>
                      <td>DEP</td>
                      <td>15:50</td>
                    </tr>
                    <tr>
                      <td>King Lane Park and Ride</td>
                      <td>DEP</td>
                      <td>16:05</td>
                    </tr>
                    <tr>
                      <td>Ring Road Fink Hill</td>
                      <td>DEP</td>
                      <td>16:15</td>
                    </tr>
                    <tr>
                      <td>Kirkstall Lights</td>
                      <td>ARR</td>
                      <td>16:35</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default HGS12ServicePage
